


/* sub menu*/
.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark .ant-menu-item-active {
    background-color: #1890ff; /* Change the background color to your desired color */
  }

.ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-active {
    background-color: #1890ff; /* Change the background color for submenu items */
  }


.App {
  height: 50vh;
  width: 100vw;
}

.map-container {
  height: 100%;
  width: 100%;
}